
import React from 'react'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'

function OfferItemList({product,language,currency}) {
    return (
        <>
            <Col xs={12} lg={6} className="px-1 text-center mb-3 h-product-card">
                <Card className="border-0">
                    <Card.Img variant="top" src={product.Images[0]['Image1']}/>
                    <Card.Body>
                        <Card.Title className={language === "AR" ? "ff-geflowbold" : "ff-bignoodletitling"}>{ product.Names[0].DigitalName }</Card.Title>
                        <Card.Text className={language === "AR" ? "ff-gesstextMedium" : "ff-galvji"}>{ product.Names[0].LongDescription }</Card.Text>
                    </Card.Body>
                    <Card.Footer>
                        <div className="row justify-content-between px-3">
                            <div className="col-1 pe-0">
                                <span className='card-title ff-bignoodletitling'>{language === "AR" ? product.Prices[0].Price+" "+currency[1] : product.Prices[0].Price+" "+currency[0]}</span>
                            </div>
                        </div>
                    </Card.Footer>
                </Card>
            </Col>
        </>
    );
}

export default OfferItemList;