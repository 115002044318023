
import React from 'react'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'

function MenuItemList({ onSelect, product, language, currency }) {
    const handleProductClick = (product) => {
        onSelect(product);
    };
    return (
        <>
            <Col xs={6} lg={3} className="px-1 text-center mb-3" onClick={() => handleProductClick(product)}>
                <Card className="border-0 h-product-card">
                    <Card.Img variant="top" src={product.Images[0]['Image1']} />
                    <Card.Body>
                        <Card.Title className={language === "AR" ? "ff-geflowbold" : "ff-bignoodletitling"}>{product.Names[0].DigitalName}</Card.Title>
                        <Card.Text className={language === "AR" ? "lh-sm ff-gesstextMedium" : "lh-sm ff-galvji"}>{product.Names[0].LongDescription?.length > 110 ? product.Names[0].LongDescription.slice(0, 100).substr(0, product.Names[0].LongDescription.slice(0, 100).lastIndexOf(" ")) + "..." : product.Names[0].LongDescription}</Card.Text>
                    </Card.Body>
                    <Card.Footer>
                        <div className="row justify-content-between pe-3">
                            <div className="col-2 pe-0">
                                <span className='card-title ff-bignoodletitling'>{language === "AR" ? product.Prices[0].Price + " " + currency[1] : product.Prices[0].Price + " " + currency[0]}</span>
                            </div>
                            <div className={language === "AR" ? "col-7 px-0 text-start ms-2" : "col-9 px-0 text-end"}>
                                {product.Names[0].Calories !== "" ? (<span className='text-cal px-1'>{language === "AR" ? product.Names[0].Calories + " س.ح" : product.Names[0].Calories + " Cal"}</span>) : ("")}
                                {
                                    product.Allergens?.map((item, index) => {
                                        return <img className="d-desktop p-icon" src={"../assets/" + item.Allergen.Icon} width="20" height="20" alt=''></img>
                                    })
                                }
                            </div>
                            <div className="d-mobile col-12 pe-0">
                                {
                                    product.Allergens?.map((item, index) => {
                                        return <img className="p-icon" src={"../assets/" + item.Allergen.Icon} width="13" height="13" alt=''></img>
                                    })
                                }
                            </div>
                        </div>
                    </Card.Footer>
                </Card>
            </Col>
        </>
    );
}

export default MenuItemList;