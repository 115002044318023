import Dropdown from 'react-bootstrap/Dropdown'
function Language({ handleLanguage, language }) {
    return (
        <Dropdown className="position-absolute z-1">
            <Dropdown.Toggle id="dropdown-language">
                <img src="../assets/language.webp" alt="language" className="dropdown-img"/>
                <span className="language-display text-light ff-gothambold ms-1">{language}</span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
                <Dropdown.Item onClick={() => handleLanguage("EN")}>English</Dropdown.Item>
                <Dropdown.Item onClick={() => handleLanguage("AR")}>Arabic</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
}

export default Language;