import React, { useRef, useEffect } from 'react'
import Alert from 'react-bootstrap/Alert'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
function Navbar1({ activeTab, direction, categories, handleNav1Click, language }) {

    const divRefN1 = useRef(null)

    useEffect(() => {
        const handleScroll = () => {
            if (divRefN1.current) {
                const { top } = divRefN1.current.getBoundingClientRect()
                // Check if the top of the div is at the top of the viewport
                if (top === 0) {
                    divRefN1.current.classList.remove('mx-3')
                    divRefN1.current.classList.add('rounded-0')
                    divRefN1.current.classList.remove('rounded-4')
                    divRefN1.current.classList.add('alert-nav1')
                } else {
                    divRefN1.current.classList.add('mx-3')
                    divRefN1.current.classList.remove('rounded-0')
                    divRefN1.current.classList.add('rounded-4')
                    divRefN1.current.classList.remove('alert-nav1')
                }
            }
        }

        // Add scroll event listener
        window.addEventListener('scroll', handleScroll)

        // Cleanup function
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    function changetab(tabId) {
        window.scrollTo(0, 250)
        handleNav1Click(tabId)
    }

    function next() {
        const activeNavItem = document.querySelector(".active-nav1")

        var nextElement = activeNavItem.nextElementSibling

        if (nextElement) {
            activeNavItem.classList.remove('active-nav1')
            nextElement.classList.add('active-nav1')
            nextElement.click()
            document.getElementById(nextElement.id).scrollIntoView({ inline: "center" })
        }
    }

    function prev() {
        const activeNavItem = document.querySelector(".active-nav1")
        var prevElement = activeNavItem.previousElementSibling
        if (prevElement) {
            activeNavItem.classList.remove('active-nav1')
            prevElement.classList.add('active-nav1')
            prevElement.click()
            document.getElementById(prevElement.id).scrollIntoView({ inline: "center" })
        }
    }
    return (
        <Alert className={language === "AR" ? 'd-flex justify-content-between align-items-center primary-bg text-light text-uppercase text-center ff-geflowbold mx-3 border-0 rounded-4 p-1 sticky-top' : 'd-flex justify-content-between align-items-center primary-bg text-light text-uppercase text-center ff-gothambold mx-3 border-0 rounded-4 p-1 sticky-top'}  ref={divRefN1}>
            <div><Image src="../assets/prev.webp" className='nav-btn' onClick={direction === "ltr" ? prev : next} /></div>
            <Row className='h-scroll mx-1 w-100' dir={direction}>
                {
                    categories?.map((item, index) => {
                        return <Col key={"navbar1-" + index} xs={4} id={"nav1-" + item.Id} onClick={() => changetab(item.Id)} className={activeTab === item.Id ? 'active-nav1 align-self-center' : 'align-self-center'}>{language === "AR" ? item.ArabicName : item.Name}</Col>
                    })
                }
            </Row>
            <div><Image src="../assets/next.webp" className='nav-btn' onClick={direction === "ltr" ? next : prev} /></div>
        </Alert>
    );
}

export default Navbar1;