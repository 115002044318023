import Button from 'react-bootstrap/Button'
function Feedback({ direction, language }) {
    function openurl(url) {
        window.location.href = url
    }
    return (
            <Button className="position-absolute bottom-0 end-0 primary-bg z-1 text-uppercase ff-gothambold border-0 rounded-pill me-3 mb-4" dir={direction} onClick={() => openurl("https://forms.office.com/r/6QkDAeGTFS")}>
                <img src="../assets/feedback.webp" alt="" className='p-0 feedback-icon mx-1' />
                {language === "AR" ? 'تعليق' : 'Feedback'}
            </Button>
    );
}

export default Feedback;