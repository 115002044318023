import React, { useState, useEffect, useRef } from 'react'
import Placeholder from 'react-bootstrap/Placeholder'
import './design'
import './scripts'
import BANNER from './components/banner'
import BODY from './components/body'
function App() {
  const [language, setLanguage] = useState("EN")
  const [currency, setCurrency] = useState(["AED", "درهم"])
  const [direction, setDirection] = useState("ltr")
  const [activeTab, setActiveTab] = useState(139)
  const [filteredSubCategories, setFilteredSubCategories] = useState([])
  // eslint-disable-next-line
  const [generation, setGeneration] = useState('31')
  const [loadingCategories, setLoadingCategories] = useState(true)
  const dataRefCat = useRef([])
  const [loadingSubCategories, setLoadingSubCategories] = useState(true)
  const dataRefSubCat = useRef([])
  const [loadingProducts, setLoadingProducts] = useState(true)
  const dataRefProducts = useRef([])
  const dataRefProductsAr = useRef([])

  function handleLanguage(lang) {
    setLanguage(lang)
    if (lang === "AR") setDirection("rtl")
    else setDirection("ltr")
  }

  useEffect(() => {
    var url_string = window.location.href
    var url = new URL(url_string)
    var countryurl = url.searchParams.get("cnt")
    var gen = ''
    switch (countryurl) {
      case "KSA":
        setGeneration(5)
        gen = 5
        setCurrency(["SAR", "ريال"])
        break;

      default:
        setGeneration(31)
        gen = 31
        setCurrency(["AED", "درهم"])
        break;
    }
    fetch('https://qrportalbackend.azurewebsites.net/category/outlet/lvl1/' + gen)
      .then(response => response.json())
      .then(data => {
        dataRefCat.current = data
        setLoadingCategories(false)
      })
      .catch(error => console.log(error))

    fetch('https://qrportalbackend.azurewebsites.net/category/outlet/lvl2/' + gen)
      .then(response => response.json())
      .then(data1 => {
        dataRefSubCat.current = data1
        setLoadingSubCategories(false)
      })
      .catch(error => console.log(error))

    fetch('https://qrportalbackend.azurewebsites.net/menu/outlet/' + gen + '/1')
      .then(response => response.json())
      .then(data2 => {
        dataRefProducts.current = data2
        fetch('https://qrportalbackend.azurewebsites.net/menu/outlet/' + gen + '/2')
          .then(response => response.json())
          .then(data2Ar => {
            dataRefProductsAr.current = data2Ar
            setActiveTab(dataRefCat.current[0].Id)
            setLoadingProducts(false)
            const subcats = dataRefSubCat.current.filter(item => item.ParentCategoryID === dataRefCat.current[0].Id)
            setFilteredSubCategories(subcats);
          })
          .catch(error => console.log(error))
      })
      .catch(error => console.log(error))
    // eslint-disable-next-line
  }, []);

  function handleNav1Click(catId) {
    setActiveTab(catId)
    const subcats = dataRefSubCat.current.filter(item => item.ParentCategoryID === catId);
    setFilteredSubCategories(subcats);
  }

  return (
    <div className="App">
      <BANNER handleLanguage={handleLanguage} language={language} direction={direction} />
      {!loadingProducts ? (<BODY activeTab={activeTab} direction={direction} categories={dataRefCat.current} loadingCategories={loadingCategories} subCategories={filteredSubCategories} loadingSubCategories={loadingSubCategories} products={dataRefProducts.current} productsAr={dataRefProductsAr.current} loadingProducts={loadingProducts} handleNav1Click={handleNav1Click} language={language} currency={currency}/>) : (<Placeholder as="p" animation="glow"><Placeholder className="vh-100" xs={12} size="lg" /></Placeholder>)}
    </div>
  );
}

export default App;
