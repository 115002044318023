import LANGUAGE from "./language"
import FEEDBACK from "./feedback"
function Banner({handleLanguage,language,direction}) {
    return (
        <div className="image-container" dir={direction}>
            <LANGUAGE handleLanguage={handleLanguage} language={language}/>
            <FEEDBACK direction={direction} language={language}/>
            <div className="overlay"></div>
            <img src="../assets/banner.webp" className="d-block w-100 top-banner background-image" alt="Background" />
            <img src="../assets/logo.webp" className="overlay-image" alt="Overlay" />
        </div>
    );
}

export default Banner;